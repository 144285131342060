import { dassert, dcache, dlog, dnetwork, drandom, TObject } from "corexxx";
import { DWeb } from "../../libs/DWeb";
import { DWebTS } from "../../libs/DWebTS";
import logo from "./../assert/logo.png";
import { UK_TYPES } from "./model";

let flagUserCreateInProgress = false;

// cache
let _getUserData: UK_TYPES.TUserState | undefined = undefined;
export namespace UkHelper {
  // helper function
  export function getSectionIdFromSubSectionId(courseDataSource: UK_TYPES.TCourseManifest | null, id?: string): string | undefined {
    if (!courseDataSource) {
      return undefined;
    }
    for (let i = 0; i < courseDataSource.outline.length; i++) {
      if (id === courseDataSource.outline[i].code) {
        return id;
      }
      for (let j = 0; j < courseDataSource.outline[i].subchapter.length; j++) {
        if (id === courseDataSource.outline[i].subchapter[j].code) {
          return courseDataSource.outline[i].code;
        }
      }
    }
  }

  export function getSectionWithId(courseDataSource: UK_TYPES.TCourseManifest | null, id?: string): UK_TYPES.TSubChapter | undefined {
    if (!courseDataSource) {
      return undefined;
    }
    for (let i = 0; i < courseDataSource.outline.length; i++) {
      if (id === courseDataSource.outline[i].code) {
        return courseDataSource.outline[i].subchapter[0];
      }
      for (let j = 0; j < courseDataSource.outline[i].subchapter.length; j++) {
        if (id === courseDataSource.outline[i].subchapter[j].code) {
          return courseDataSource.outline[i].subchapter[j];
        }
      }
    }
  }

  export function getNextSection(courseDataSource: UK_TYPES.TCourseManifest | null, id?: string) {
    if (!courseDataSource) {
      return undefined;
    }
    for (let i = 0; i < courseDataSource.outline.length; i++) {
      for (let j = 0; j < courseDataSource.outline[i].subchapter.length; j++) {
        if (id === courseDataSource.outline[i].subchapter[j].code) {
          if (j < courseDataSource.outline[i].subchapter.length - 1) {
            return courseDataSource.outline[i].subchapter[j + 1];
          } else if (i < courseDataSource.outline.length - 1) {
            return courseDataSource.outline[i + 1].subchapter[0];
          } else {
            return courseDataSource.outline[0].subchapter[0];
          }
        }
      }
    }
    return courseDataSource.outline[0].subchapter[0];
  }

  // app config
  export const appConfig: DWeb.TDPageRootLayoutConfig = {
    title: "",
    logo: logo,
    appBarTrasparent: true,
    appBarStyle: { background: "#0a3f6b" },
    appBarTextStyle: { color: "white" },
    rightActions: [
      { text: "dashboard", href: "/dashboard", showAfterLogin: true },
      { text: "Mock test", href: "/test" },
      { text: "FAQ", href: "/faq" },
    ],
    hideFooter: false,
  };

  // Define the getRandom APIs
  let cache: TObject[] | null = null;
  export async function getRandom(force?: boolean, limit: number = 5): Promise<TObject[] | null> {
    if (cache && !force) {
      return cache;
    }
    let result = await dnetwork.postSimpleStore(UK_TYPES.GET_RANDOM_API, { _limit: limit });
    cache = DWebTS.decode(result.out) as TObject[];
    return cache;
  }

  export const copy_text = {
    mission_title: "Our mission is to provide a structured and fun way to prepare for “Life in the UK Test” to achieve ILR or UK citizenship.",
    mission_desc: `Dear Aspirants,<br/><br/>The life in the UK test is an essential test which you need to pass to get the <b>ILR or UK Citizenship</b>. In this test, there are 24 questions which you need to answer in 45 min and you have to score 75% to pass it. The Life in the UK test is by no means easy. In fact, many British citizens struggle to answer the questions asked in the test.<br/><br/>But with the right set of preparation and practice, the Life in the UK test is not hard. Unlike other online resources, 'Crack The Life in the UK Test' course will provide a <b>structured guidance</b> and prepare your for the cracking the test confidently.<br/><br/>Our <b>well designed “Confidence Score” </b>will tell you how ready you are for the test. It shows up the gaps and provides the guidance to improve those gaps. This makes you 100% prepared before your real interview.<br/><br/><br/>Regards,<br>Crack the Life In the UK Test Team.`,
    mock_test_desc: `<p>Hello,</p>
<p><span style="text-align: inherit;">Thanks for joining the &ldquo;Life in the UK Mock test&rdquo;. This test would be remarkably similar to the test you are giving in the real exam. <b>The test has not yet started, please have a look at the instructions below and click the start button when ready</b>.&nbsp;</span></p>
<ol>
    <li>
        <p>You are going to have twenty-four multiple choice questions which you need to answer in 45 minutes.&nbsp;</p>
    </li>
    <li>
        <p>While giving the answer, you can skip any question if you feel you don&apos;t know and can revisit later.&nbsp;</p>
    </li>
    <li>
        <p>In the button bar, you will have &quot;Next&quot;, &quot;Previous&quot; button where you can move the previous and next section. The skip button will Skip to the next question. The Reset button will unselect the question.&nbsp;</p>
    </li>
    <li>
        <p>At the end, you can see an &quot;End button&quot; to end the test. Once you finish the test, it can&rsquo;t be resumed.&nbsp;</p>
    </li>
    <li>
        <p>Once you finish the question, the result will be shown immediately, and a Mock test certificate will be provided for your review purpose.&nbsp;</p>
    </li>
    <li>
        <p>At the end, you will get a choice to get the membership. <b>If you score more than 50%, you would get 50% discount off your membership.</b></p>
    </li>
</ol>
<p><br>Thanks for reading all the instructions and we wish you all the best for the interview.&nbsp;</p>
<p>Thanks,<br>Crack the life in the UK Test team.<br></p>`,
  };

  // return user data
  export async function getUserData(user_id: string): Promise<UK_TYPES.TUserState> {
    if (_getUserData) {
      dlog.d("cache hit - returned;");
      return _getUserData;
    }
    let data = await dnetwork.getSimpleStore(`${UK_TYPES.USER_DB_ENDPOINT}?_id=${user_id}`);
    // if not found - create one
    if (data.out.length === 0) {
      if (flagUserCreateInProgress) {
        throw new Error("User not yer existing creating....");
      }
      flagUserCreateInProgress = true;
      await dnetwork.postSimpleStore(`${UK_TYPES.USER_DB_ENDPOINT}/insert`, {
        _id: user_id,
        state: {},
      });
      data = await dnetwork.getSimpleStore(`${UK_TYPES.USER_DB_ENDPOINT}?_id=${user_id}`);
    }
    _getUserData = data.out[0] as UK_TYPES.TUserState;
    return _getUserData;
  }

  export async function updateUserState(data: TObject) {
    if (!_getUserData?._id) {
      return;
    }
    let payload: TObject = {
      _id: _getUserData?._id,
      ...data,
    };

    await dnetwork.postSimpleStore(`${UK_TYPES.USER_DB_ENDPOINT}/update`, payload);
  }

  // getting quiz data
  async function _getQuizData(catagories: string): Promise<UK_TYPES.TQuestion[]> {
    let data = await dnetwork.getSimpleStore(`${UK_TYPES.QUIZ_ENDPOINT}?categories=${catagories}&_limit=100`);
    return DWebTS.decode(data.out) as UK_TYPES.TQuestion[];
  }
  export const getQuizData = dcache.memoizeAsync(_getQuizData) as (catagories: string) => Promise<UK_TYPES.TQuestion[]>;

  export async function _getCourseManifestWithOutModify(): Promise<UK_TYPES.TCourseManifest> {
    let data = await dnetwork.getSimpleStore(`${UK_TYPES.MANIFEST_ENDPOINT}`);
    if (data.out[0]) {
      let courseManifest = data.out[0] as UK_TYPES.TCourseManifest;
      return courseManifest;
    }
    throw new Error("Not able to fetch meta information");
  }

  // get course outline
  async function _getCourseManifest(): Promise<UK_TYPES.TCourseManifest> {
    let data = (await _getCourseManifestWithOutModify()) as UK_TYPES.TCourseManifest;
    let courseManifest = DWebTS.deepCopy(data) as UK_TYPES.TCourseManifest;
    _updateCouseModelInplace(courseManifest as UK_TYPES.TCourseManifest);
    return courseManifest;
  }

  function _updateCouseModelInplace(courseManifest: UK_TYPES.TCourseManifest) {
    courseManifest.outline?.forEach((x, idx) => {
      if (courseManifest.config?.add_chapter_number) {
        x.title = `Ch ${idx + 1}. ${x.title}`;
      }
      x.scoreState = { total_count: 0, correct_count: 0 };
      x.completionState = { total_count: 0, correct_count: 0 };
      x.subchapter.forEach((y, idy) => {
        y.scoreState = { total_count: 0, correct_count: 0 };
        y.completionState = { total_count: 0, correct_count: 0 };
        if (courseManifest.config?.add_chapter_number) {
          y.title = `${idx + 1}.${idy + 1} ${y.title}`;
        }
      });
    });
    courseManifest.scoreState = { total_count: 0, correct_count: 0 };
    courseManifest.completionState = { total_count: 0, correct_count: 0 };
  }

  export function buildCourseViewModel(courseManifest: UK_TYPES.TCourseManifest, userData: UK_TYPES.TUserState): UK_TYPES.TCourseManifest {
    //let newCourseViewModel = DWebTS.deepCopy(courseManifest) as UK_TYPES.TCourseManifest;
    // use it as this will convert readonly data to wriable data
    let newCourseViewModel = JSON.parse(JSON.stringify(courseManifest)) as UK_TYPES.TCourseManifest;
    newCourseViewModel.outline?.forEach((x) => {
      // loop over sub chapter
      x.subchapter.forEach((y) => {
        if (userData.state[y.code]) {
          y.scoreState = userData.state[y.code];
          y.completionState = { correct_count: 1, total_count: 1 };
        } else {
          if (y.type == "multiple_choice_test") {
            y.scoreState = { correct_count: 0, total_count: (y.data as any[]).length };
          }
          y.completionState = { correct_count: 0, total_count: 1 };
        }
      });
      x.scoreState = {
        total_count: x.subchapter.reduce((p, c) => p + (c.scoreState?.total_count || 0), 0),
        correct_count: x.subchapter.reduce((p, c) => p + (c.scoreState?.correct_count || 0), 0),
      };
      x.completionState = {
        total_count: x.subchapter.reduce((p, c) => p + (c.completionState?.total_count || 0), 0),
        correct_count: x.subchapter.reduce((p, c) => p + (c.completionState?.correct_count || 0), 0),
      };
    });
    newCourseViewModel.scoreState = {
      total_count: newCourseViewModel.outline.reduce((p, c) => p + (c.scoreState?.total_count || 0), 0),
      correct_count: newCourseViewModel.outline.reduce((p, c) => p + (c.scoreState?.correct_count || 0), 0),
    };
    newCourseViewModel.completionState = {
      total_count: newCourseViewModel.outline.reduce((p, c) => p + (c.completionState?.total_count || 0), 0),
      correct_count: newCourseViewModel.outline.reduce((p, c) => p + (c.completionState?.correct_count || 0), 0),
    };

    return newCourseViewModel;
  }

  export const getCourseManifest = dcache.memoizeAsync(_getCourseManifest) as () => Promise<UK_TYPES.TCourseManifest>;

  export async function updateCourseManifest(input: TObject) {
    _validateManifest(input as UK_TYPES.TCourseManifest);
    let data = await dnetwork.postSimpleStore(`${UK_TYPES.MANIFEST_ENDPOINT}/update`, { ...input });
    if (data.out[0]) {
      return data.out[0] as UK_TYPES.TCourseManifest;
    }
    throw new Error("Not able to fetch meta information");
  }

  function _validateManifest(input: UK_TYPES.TCourseManifest) {
    let codeSet: Set<string> = new Set(undefined);
    function _addOrThrow(data: string) {
      if (codeSet.has(data)) {
        throw new Error("Duplicate code:" + data);
      }
      codeSet.add(data);
    }
    _addOrThrow(input.code);
    for (let x of input.outline) {
      _addOrThrow(x.code);
      for (let y of x.subchapter) {
        _addOrThrow(y.code);
      }
    }
  }

  export function __assignRandomCode(input: UK_TYPES.TCourseManifest) {
    input.code = drandom.generateFriendlyId();
    for (let x of input.outline) {
      x.code = drandom.generateFriendlyId();
      for (let y of x.subchapter) {
        y.code = drandom.generateFriendlyId();
      }
    }
  }

  export async function setUserSubmissionData(payload: TObject) {
    dassert.verifyNotNullAndEmpty(_getUserData?._id, "you must be login");
    payload["user_id"] = _getUserData!._id;
    let hash = _getUserData!._id + payload.code;
    payload["hash"] = hash;
    let res = await dnetwork.postSimpleStore(`${UK_TYPES.USER_DB_ENDPOINT}/insertorupdate`, {
      _payload: [payload],
      _field: "hash",
    });
    console.log(res);
  }

  export async function getUserSubmissionData(code: string) {
    dassert.verifyNotNullAndEmpty(_getUserData?._id, "you must be login");
    let hash = _getUserData!._id + code;
    let data = await dnetwork.getSimpleStore(`${UK_TYPES.USER_DB_ENDPOINT}/?hash=${hash}`);
    return data.out[0];
  }

  export function getPercentageFromScore(score?: UK_TYPES.TScore): number {
    if (!score || score.total_count == 0) return 0;
    let res = (score.correct_count / score.total_count) * 100;
    return parseFloat(res.toFixed(2));
  }
}
