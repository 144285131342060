import { DWeb } from "../../libs/DWeb";
import { UkData } from "../data/data";
import { AppHolder } from "./Utils";

export const FAQ = () => {
  return (
    <AppHolder>
      <DWeb.DHelmet config={{ ...UkData.AppConfig.seo, page_title: "FAQ  | Crack Life in the Uk Test" }} />
      <DWeb.ResponsiveWindow style={{ paddingTop: 30 }}>
        <DWeb.DFAQ
          header="Life in the UK test Information"
          data={[
            {
              title: "About the test",
              qa: [
                {
                  q: "What is the life in the UK test?",
                  a: "Life in the UK test is a computer based multiple choice test containing 24 questions and is designed to test applicant's knowledge of life in the United Kingdom.",
                },
                {
                  q: "Who has to do the test?",
                  a: "Everybody in the age bracket of 18-64 is required to pass this test as part of their citizenship or indefinite leave to remain (ILR) application",
                },
                {
                  q: "Who is exempt from the test?",
                  a: `You don’t need to take the test if:

You're under 18 or over 65
Unable to, because of special circumstances (such as a long-term physical or mental condition; you will need to provide necessary proof)
You only need to pass the test once – you don't need to pass the test again if you're applying to become a British citizen and previously passed the life in the UK test as part of your settlement (ILR) application.

`,
                },
                {
                  q: "What is the passmark for the test?",
                  a: "You must score 75% or more to pass the test. This is equivalent to answering 18 or more out of 24 questions correctly.",
                },
                {
                  q: "What is the format of the Life in the UK Test?",
                  a: "The test is computer-based and consists of 24 multiple-choice questions. Test-takers have 45 minutes to complete the test",
                },
                {
                  q: "How much does the Life in the UK Test cost?",
                  a: "As of 2021, the cost of the test is £50.",
                },
                {
                  q: "What topics are covered in the Life in the UK Test?",
                  a: "The test covers a wide range of topics related to British culture, history, and government, including British customs and traditions, the legal and political system, and famous British people and events.",
                },
                {
                  q: "How can I prepare for the Life in the UK Test?",
                  a: "There are a variety of resources available to help you prepare for the test, including official practice tests, study guides, and online tutorials. It's also recommended to read about UK culture, history and politics",
                },
                {
                  q: "How many times can I take the Life in the UK Test?",
                  a: "If you fail the test, you can retake it as many times as necessary, but you will need to pay the test fee each time. However, you need to wait for 3 working days before taking the test again.",
                },
              ],
            },
            {
              title: "Booking the test",
              qa: [
                {
                  q: "How do I book the Life in the UK Test??",
                  a: "To book the Life in the UK Test, you need to visit the official website of UK Visas and Immigration and create an account. From there, you can select a test center and date that is convenient for you.",
                },
                {
                  q: "What is the process for booking the Life in the UK Test??",
                  a: "The process for booking the Life in the UK Test typically involves creating an account on the UK Visas and Immigration website, choosing a test center and date, and paying the test fee.",
                },
                {
                  q: "Are there any specific requirements for booking the Life in the UK Test??",
                  a: "You need to have a valid debit or credit card, a valid email address, and a valid passport or other form of identification to book the test.",
                },
                {
                  q: "How can I prepare for the Life in the UK Test?",
                  a: "There are a variety of resources available to help you prepare for the test, including official practice tests, study guides, and online tutorials. It's also recommended to read about UK culture, history and politics",
                },
                {
                  q: "How far in advance can I book the Life in the UK Test?",
                  a: "You can book the test up to 3 months in advance.",
                },
                {
                  q: "Can I change the date or location of my Life in the UK Test after booking?",
                  a: "Yes, you can change the date or location of your test after booking, but you will need to pay an additional fee for this service. You should contact the test center for further details.",
                },
              ],
            },
            {
              title: "What to expect at test center?",
              qa: [
                {
                  q: "What should I bring to the Life in the UK Test center?",
                  a: "You should bring a valid form of identification, such as a passport or national identity card, and a printed copy of your test booking confirmation.",
                },
                {
                  q: "What is the procedure for entering the test center?",
                  a: "You will be required to go through a security check and have your identification verified before being allowed to enter the test center.",
                },
                {
                  q: "Can I bring personal items into the test center?",
                  a: "Personal items such as bags, mobile phones, and other electronic devices are not allowed in the test center during the test. You will be required to leave them in designated storage areas before entering the testing room.",
                },
                {
                  q: "Are there any breaks during the Life in the UK Test?",
                  a: "The test is 45 minutes long and there are no breaks during the test.",
                },
                {
                  q: "How far in advance can I book the Life in the UK Test?",
                  a: "You can book the test up to 3 months in advance.",
                },
                {
                  q: "What happens if I finish the test early?",
                  a: "If you finish the test early, you will be required to wait until the end of the test period before leaving the test center.",
                },
              ],
            },
            {
              title: "After test and results",
              qa: [
                {
                  q: "How will I receive my test results?",
                  a: "You will receive your test results immediately after completing the test. If you pass, you will receive a print out of your test results. If you fail, you will receive a print out of your test results and the questions you got wrong.",
                },
                {
                  q: "What happens if I fail the Life in the UK Test?",
                  a: "If you fail the Life in the UK Test, you will need to retake the test and pay the test fee again. However, you need to wait for 3 working days before taking the test again.",
                },
                {
                  q: "How long are the Life in the UK Test results valid for?",
                  a: "Life in the UK Test results are valid for 2 years, after that you will have to take the test again.",
                },
                {
                  q: "How can I check if my test results are valid?",
                  a: "You can check the validity of your test results by contacting the test center where you took the test.",
                },
                {
                  q: "Can I appeal my test results if I fail the Life in the UK Test?",
                  a: "There is no formal appeal process for the Life in the UK Test. If you feel that your test results were incorrect, you can retake the test.",
                },
              ],
            },
            {
              title: "Common Mistakes",
              qa: [
                {
                  q: "What are some common mistakes that test-takers make during the Life in the UK Test?",
                  a: "Some common mistakes include not studying enough before the test, not fully understanding the question, or not answering all questions. Additionally, not being familiar with the format of the test, not knowing the test center's location, and not having all the required documents are also common mistakes.",
                },
                {
                  q: "How can I avoid making mistakes during the Life in the UK Test?",
                  a: "To avoid making mistakes during the test, it's important to study and practice beforehand using official resources such as practice tests and study guides. ",
                },
                {
                  q: "How can I overcome test anxiety during the Life in the UK Test?",
                  a: "To overcome test anxiety, you can try different techniques like deep breathing, visualization, and positive affirmations. You can also familiarize yourself with the test format and content, and practice with mock tests to build your confidence.",
                },
                {
                  q: "What should I do if I encounter a language barrier during the Life in the UK Test?",
                  a: "If you encounter a language barrier during the test, you can ask for an interpreter or a language support, but you need to inform the test center in advance and provide documents to support your claim.",
                },
                {
                  q: "How can I avoid not understanding the question during the Life in the UK Test?",
                  a: "To avoid not understanding the question, you can familiarize yourself with the test format and content, read the questions carefully and take your time to understand them.",
                },
                {
                  q: "What should I do if I make a mistake during the Life in the UK Test?",
                  a: "If you make a mistake during the test, you should not panic, stay calm, and continue answering the next questions to the best of your ability. Remember that you will have a chance to retake the test if you fail.",
                },
              ],
            },
            {
              title: "About this website",
              qa: [
                {
                  q: "What is the 'Crack Like in the UK' online course?",
                  a: 'The "Crack Like in the UK" online course is a comprehensive study program designed to help individuals prepare for the Life in the UK Test. It includes study materials, practice tests, and interactive exercises to help test-takers gain a thorough understanding of British culture, history, and government.',
                },
                {
                  q: 'What topics are covered in the "Crack Like in the UK" online course?',
                  a: "The course covers a wide range of topics related to British culture, history, and government, including British customs and traditions, the legal and political system, and famous British people and events. It also includes detailed explanations of the test format and provides strategies to tackle the test.",
                },
                {
                  q: 'How long does the "Crack Like in the UK" online course take to complete?',
                  a: 'The course is self-paced, so it can take anywhere from a few weeks to several months to complete, depending on the individual"s study habits and schedule.',
                },
                {
                  q: 'Is the "Crack Like in the UK" online course suitable for non-native English speakers?',
                  a: "Yes, the course is designed to be accessible to non-native English speakers and includes language support",
                },
                {
                  q: 'What happens if I fail the Life in the UK Test after taking the "Crack Like in the UK" online course?',
                  a: "The course is designed to help individuals pass the Life in the UK Test, but in case you fail, the course offers a retake policy and support to help you pass the test on the next attempt.",
                },
              ],
            },
            {
              title: "Membership and Refund policies",
              qa: [
                {
                  q: 'How can I become a member of the "Crack Like in the UK" online course?',
                  a: 'To become a member of the "Crack Like in the UK" online course, you will need to visit the website and register for the course by providing your personal details and payment information of 5 pound',
                },
                {
                  q: 'What is the cost of the "Crack Like in the UK" online course?',
                  a: "The cost of the course is fixed at 5 pounds for a 1-year subscription.",
                },
                {
                  q: 'How long does the membership last for the "Crack Like in the UK" online course?',
                  a: 'The membership for the "Crack Like in the UK" online course is 1 year.',
                },
                {
                  q: 'What is the refund policy for the "Crack Like in the UK" online course?',
                  a: 'The refund policy for the "Crack Like in the UK" online course is 7 days. If you are not satisfied with the course, you can request a refund within 7 days of purchase.',
                },
                {
                  q: 'Can I cancel my membership for the "Crack Like in the UK" online course?',
                  a: "If you wish to cancel your membership, you can do so by contacting the customer service. However, please note that once the 7-day refund period has passed, no refunds will be issued.",
                },
              ],
            },
            {
              title: "Feedback and Contribution",
              qa: [
                {
                  q: 'How can I give feedback on the "Crack Like in the UK" online course website?',
                  a: "You can give feedback on the website by contacting customer service or by filling out a feedback form on the website.",
                },
                {
                  q: 'How can I promote the "Crack Like in the UK" online course?',
                  a: "You can promote the course by sharing it on social media, telling your friends and family about it, and leaving positive reviews on the website.",
                },
                {
                  q: 'Can I contribute to the development of the "Crack Like in the UK" online course?',
                  a: "Yes, you can contribute to the development of the course by providing feedback, suggestions, and by sharing your own knowledge and experience. You can contact the course developer to know more about the opportunities.",
                },
                {
                  q: 'Is there any reward for promoting the "Crack Like in the UK" online course?',
                  a: "There may be rewards for promoting the course, such as a discount on future purchases or a referral bonus. You should check the official website or contact customer service for more details",
                },
                {
                  q: 'Can I suggest new content for the "Crack Like in the UK" online course?',
                  a: "Yes, you can suggest new content for the course by contacting customer service or by filling out a suggestion form on the website. Your suggestions will be taken into consideration for future updates and development of the course.",
                },
              ],
            },
          ]}
        />
      </DWeb.ResponsiveWindow>
    </AppHolder>
  );
};
