import { DWeb } from "../../libs/DWeb";
import logo from "../assert/logo.png";

export namespace UkData {
  export const AppConfig: DWeb.TAppConfig = {
    logo: logo,
    primary_color: 'red',
    app_name: "life in the uk",
    app_id: 'crack_life_in_the_uk',
    subscription_key: "crack_life_in_the_uk",
    google_analytics_tag: "G-BKYXF939QH",
    stripe_public_key: "pk_live_51LvQTTCtA5cdHPVpnJAA9mLOH6kemyAGosAtdwrC4Bq9hgO8LWU4ykw8tlHApDjLxKNAAfo0Ka5hd6tsJtym6UgS00O4P7ptyK",
    seo: {
      page_title: "Life in the UK Test Online Course: Prepare for Citizenship",
      page_description:
        "Prepare for the Life in the UK test with our comprehensive online course. Study material, practice tests, and expert instruction included. Start your path to UK citizenship today.",
      keywords:
        "Life in the UK test, online course, UK citizenship test, preparation, study guide, practice tests, training, exam prep, tutorial, study material, immigrants",
    },
    google_client_id: "386493304480-nv1q8pcnhrppvn1bb8mpi3ou6ugh3287.apps.googleusercontent.com",
    social: {
      email: "mailto:dsofthelp@outlook.com",
      fb_page: "https://www.facebook.com/profile.php?id=100087654423509",
      linkedin: "https://www.linkedin.com/company/dsoftware-platfroms",
      release_note: "https://www.linkedin.com/company/92925115/admin/products/dsoftware-platfroms-crack-life-in-the-uk-test/"
    },
  };
}
