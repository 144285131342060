import { Alert, Button, Step, StepLabel, Stepper } from "@mui/material";
import { dcolors } from "corexxx";
import { DWeb } from "../../libs/DWeb";
import { UkHelper } from "../data/network";
import { useCourse } from "./Hook";

export const AppHolder = ({ children }: { children: any }) => {
  const appCommand = DWeb.useAppCommand();
  return (
    <DWeb.DPageRootLayout config={UkHelper.appConfig} style={{ backgroundColor: "#f1f1f1", margin: 0, padding: 0 }}>
      {children}
    </DWeb.DPageRootLayout>
  );
};

export const TimelineNotification = () => {
  const course = useCourse()
  if (!course.userState.exam_timestamp) {
    return <Alert
      style={{ marginBottom: 20 }}
      variant="filled" severity="warning"
      action={
        <DWeb.DRow>
          <Button variant="outlined" color="inherit" size="small" style={{ marginRight: 20 }}>
            Set schedule
          </Button>
          <Button variant="outlined" color="inherit" size="small" href={"https://www.lituktestbooking.co.uk/lituk-web/"} target="_b
          ">
            Book exam
          </Button>
        </DWeb.DRow>
      }
    >
      <DWeb.DText style={{ color: 'white' }}>Please book your example and let us know your schedule</DWeb.DText>
    </Alert>
  }
  return null;
}


export const YourPlans = () => {
  const course = useCourse()
  let planConfig = [{
    stepName: 'Schedule Test',
    component: null,
    isComplete: false
  }, {
    stepName: 'Read materials',
    component: null,
    isComplete: false
  }, {
    stepName: 'Join Discord',
    component: null,
    isComplete: false
  }, {
    stepName: 'Practice Test',
    component: null,
    isComplete: false
  }]


  return <DWeb.DBox style={{ marginBottom: 10, flex: 0, background: dcolors.pallets.blue600 }}>
    <DWeb.DText style={{ color: 'white', fontSize: 20, textAlign: 'center', marginBottom: 20, textTransform: 'uppercase' }}>Our guideline to prepare</DWeb.DText>
    <Stepper activeStep={0} alternativeLabel>
      {planConfig.map((config) => (
        <Step key={config.stepName}>
          <StepLabel style={{ color: 'white' }}>{config.stepName}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </DWeb.DBox>
}
