/* eslint-disable array-callback-return */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, LinearProgress, Typography } from "@mui/material";
import { TObject, TStringCallback, TVoidCalBack, dassert, dcolors, dlog, dnetwork } from "corexxx";
import React from "react";
import _ from "underscore";
import { DWeb } from "../../libs/DWeb";
import { DWebTS } from "../../libs/DWebTS";
import { UkData } from "../data/data";
import { UK_TYPES } from "../data/model";
import { UkHelper } from "../data/network";
import { AdminSetup } from './AdminSetup';
import { ConfidenceScore } from "./ConfidenceScore";
import { useCourse } from "./Hook";
import { QuestionSetUI } from "./MCQ";
export type TCouseState = { expanded_id: string | undefined; progressState: TObject };

// This define the main dashboard
export const Dashboard = () => {
  const courseCommand = useCourse();
  const [contentRef, setContentRef] = React.useState<UK_TYPES.TSubChapter | undefined>();
  const [state1, setState1] = React.useState<string>("score");
  React.useEffect(() => {
    courseCommand.init()
  }, [])


  return (
    <DWeb.DPageRootLayoutWithDrawer
      config={UkHelper.appConfig}
      drawerOpen={true}
      drawerContent={
        <CourseOutline
          onClick={(state) => { setState1(state); }}
          onClickItem={async (x) => {
            setContentRef(x);
          }}
        />
      }
    >
      <DWeb.DHelmet config={{ ...UkData.AppConfig.seo, page_title: "Dashboard  | Crack Life in the Uk Test" }} />
      <DWeb.ResponsiveWindow>
        {state1 == "set_paper" ? (
          <AdminSetup
            data={contentRef}
          />
        ) : null}

        {state1 == "score" ? (
          <ConfidenceScore
            improveScoreClicked={async (x) => {
              setState1("question");
              setContentRef(x);
            }}
            setPaperClicked={async (x) => {
              setState1("set_paper");
              setContentRef(x);
            }}
          />
        ) : null}    {state1 == "question" ? (<ContentUI
          data={contentRef}
          onMoveNext={() => {
            if (contentRef) setContentRef(UkHelper.getNextSection(courseCommand.courseViewModel, contentRef.code));
          }}
          onComplete={async (id: string, score: UK_TYPES.TScore) => {
            courseCommand.updateCompletion(id, score);
          }}
        />) : null}
      </DWeb.ResponsiveWindow>
    </DWeb.DPageRootLayoutWithDrawer>
  );
}

// This define the lest course outline
export const CourseOutline = ({ onClickItem, onClick }: { onClickItem: (contentRef: UK_TYPES.TSubChapter) => void; onClick: TStringCallback }) => {
  let appCommand = DWeb.useAppCommand();
  const courseCommand = useCourse();
  const handleChange = (panel: string) => (event: any, newExpanded: boolean) => {
    courseCommand.setCurrentSecCode(newExpanded ? panel : "false");
  };

  React.useEffect(() => {
    dlog.d("Dipankar=>" + courseCommand.current_sec_code);
  }, [courseCommand.current_sec_code]);

  if (!courseCommand.courseViewModel) {
    return (
      <DWeb.DCol style={{ background: "linear-gradient(to right, #2193b0, #6dd5ed)", padding: 6 }}>
        <DWeb.DBox style={{ flex: 0 }}>
          <DWeb.DLoadingUI />
        </DWeb.DBox>
      </DWeb.DCol>
    );
  }
  return (
    <DWeb.DCol style={{ background: "linear-gradient(to right, #2193b0, #6dd5ed)", padding: 6 }}>
      <DWeb.DBox style={{ flex: 0, marginBottom: 10 }}>
        <DWeb.DText mode="topper" style={{ marginTop: 16 }}>
          Welcome {appCommand.accountState?.name || "Guest"}
        </DWeb.DText>
        <DWeb.DText mode="title" style={{ marginBottom: 16, marginTop: 16 }}>
          {courseCommand.courseViewModel?.title}
        </DWeb.DText>

        <LinearProgress
          value={UkHelper.getPercentageFromScore(courseCommand.courseViewModel?.completionState)}
          variant="determinate"
          style={{ marginBottom: 16 }}
        />
        <DWeb.DRow style={{ marginBottom: 16, marginTop: 16 }}>
          <DWeb.DText mode="subtitle" style={{ flex: 1 }}>
            {UkHelper.getPercentageFromScore(courseCommand.courseViewModel?.completionState)}% complete
          </DWeb.DText>
          <Chip
            color="info"
            label="Check Confidence Level"
            onClick={() => {
              onClick("score");
            }}
          />
        </DWeb.DRow>
      </DWeb.DBox>

      <DWeb.DCol style={{}}>
        {courseCommand.courseViewModel?.outline?.map((x: UK_TYPES.TChapter, idx: any) => {
          return (
            <Accordion disableGutters={false} expanded={courseCommand.current_sec_code === x.code} onChange={handleChange(x.code)} key={idx}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <DWeb.DRow style={{ alignItems: 'center' }}>
                  {UkHelper.getPercentageFromScore(x.completionState) === 100 ? (
                    <CheckCircleIcon style={{ marginRight: 10 }} />
                  ) : (
                    <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />
                  )}
                  <Typography style={{ flex: 1, marginRight: 10 }}>{x.title}</Typography>
                  <Typography
                    style={{ color: dcolors.pallets.grey500, paddingLeft: 10, paddingRight: 10 }}
                  >{`${x.completionState.correct_count}/${x.completionState.total_count}`}</Typography>
                </DWeb.DRow>
              </AccordionSummary>
              <AccordionDetails>
                <DWeb.DCol>
                  {x.subchapter.map((y: UK_TYPES.TSubChapter, idy: any) => {
                    let enabled = appCommand.getSubscription() != null;
                    return (
                      <Button
                        disabled={!enabled}
                        key={idy}
                        size="small"
                        style={{ flex: 1, width: "100%", textDecoration: "lowercase", padding: 0 }}
                        onClick={() => {
                          onClickItem(y);
                          onClick("question");
                        }}
                      >
                        <DWeb.DCol>
                          {idy !== 0 ? (
                            <div style={{ background: enabled ? "#1976d2" : dcolors.pallets.grey300, width: 3, height: 8, marginLeft: 8 }}></div>
                          ) : null}
                          <DWeb.DRow>
                            {UkHelper.getPercentageFromScore(y.completionState) === 100 ? (
                              <CheckCircleIcon style={{ marginRight: 10, fontSize: 20, zIndex: 1 }} />
                            ) : (
                              <RadioButtonUncheckedIcon style={{ marginRight: 10, fontSize: 20, zIndex: 1 }} />
                            )}
                            <DWeb.DText style={{ margin: 0, textAlign: "left", textTransform: "capitalize", fontWeight: "normal", flex: 1 }}>
                              {y.title}
                            </DWeb.DText>
                            {enabled ? null : <LockIcon style={{ width: 20, height: 20 }} />}
                          </DWeb.DRow>
                          {idy !== x.subchapter.length - 1 ? (
                            <div style={{ background: enabled ? "#1976d2" : dcolors.pallets.grey300, width: 3, height: 8, marginLeft: 8 }}></div>
                          ) : null}
                        </DWeb.DCol>
                      </Button>
                    );
                  })}
                </DWeb.DCol>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DWeb.DCol>
    </DWeb.DCol>
  );
};

// This define the main content UI
export const ContentUI = ({
  data,
  onMoveNext,
  onComplete,
}: {
  data?: UK_TYPES.TSubChapter;
  onMoveNext: TVoidCalBack;
  onComplete: (code: string, score: UK_TYPES.TScore) => void;
}) => {
  let appCommand = DWeb.useAppCommand();
  function getRightType(data: UK_TYPES.TSubChapter) {
    if (data.type === "multiple_choice_test") {
      return (
        <DWeb.DelayRenderer
          url={`${UK_TYPES.QUIZ_ENDPOINT}?categories=${data.data}&_limit=100`}
          renderOnData={(res: any) => {
            let qn = DWebTS.decode(res.out) as UK_TYPES.TQuestion[];
            // randomize in the client side
            //qn = _.shuffle(qn); << avoid friction
            qn.forEach((x) => (x.options = _.shuffle(x.options)));
            return (
              <QuestionSetUI
                config={{
                  show_skip_btn: true,
                  title: data.title || "hello",
                  immediate_response: true,
                  show_action_bar: true,
                  showNavigation: true,
                  navigationClickDisabled: false,
                  onCompleteSet: (score) => {
                    onComplete(data.code, score);
                  },
                  isAdmin: appCommand.isDebugMode(),
                  onSaveResult: async (id, answer) => {
                    await appCommand.fireAndForget(async () => {
                      dassert.verifyOrCrash(answer.trim().length > 0, "You must have a valid answer");
                      await dnetwork.postSimpleStore(UK_TYPES.QUIZ_ENDPOINT + "/update", { _id: id, answer: answer });
                    });
                  },
                  actionButtons: {
                    finish: () => {
                      onMoveNext();
                    },
                  },
                }}
                questions={qn}
              />
            );
          }}
        />
      );
    } else if (data.type === "richtext") {
      return (
        <>
          <DWeb.DelayRenderer
            url={`${UK_TYPES.QUIZ_ENDPOINT}?code=${data.code}`}
            renderOnData={(res: any) => {
              let content: UK_TYPES.TContextTypeHtml = DWebTS.decode(res.out)[0];
              return (
                <>
                  <DWeb.Html html={content?.explanation}></DWeb.Html>
                </>
              );
            }}
          />
        </>
      );
    } else {
      return <p>Not able to load the module</p>;
    }
  }

  if (!data) {
    return null;
  }

  return (
    <DWeb.DBox style={{ margin: 20, flex: 0, padding: 0 }}>
      <DWeb.DRow style={{ borderBottom: "1px solid grey", flex: 0, padding: 20 }}>
        <DWeb.DText>{data.title}</DWeb.DText>
      </DWeb.DRow>
      <DWeb.DCol style={{ padding: 20 }}>{getRightType(data)}</DWeb.DCol>
      {data.show_skip_option || data.type === "richtext" ? <NextActionBar onComplete={onComplete} subchapter={data} onMoveNext={onMoveNext} /> : null}
    </DWeb.DBox>
  );
};

// Define the helper action bar
export const NextActionBar = ({
  subchapter,
  onMoveNext,
  onComplete,
}: {
  subchapter: UK_TYPES.TSubChapter;
  onMoveNext: TVoidCalBack;
  onComplete: (id: string, score: UK_TYPES.TScore) => void;
}) => {
  return (
    <DWeb.DRow style={{ borderTop: "1px solid grey", flex: 0, padding: 20, justifyContent: "center" }}>
      <Button
        variant="outlined"
        style={{ marginRight: 10 }}
        onClick={() => {
          onComplete(subchapter.code, { correct_count: 0, total_count: 1 });
          onMoveNext();
        }}
      >
        Mark incomplete
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          onComplete(subchapter.code, { correct_count: 1, total_count: 1 });
          onMoveNext();
        }}
      >
        Continue &gt;
      </Button>
    </DWeb.DRow>
  );
};
