import { dassert, dnetwork } from "corexxx";
import { DWeb } from "../../libs/DWeb";
import { DWebTS } from "../../libs/DWebTS";
import { UK_TYPES } from "../data/model";
import { OneQuestion } from "./MCQ";

export const AdminSetup = ({ data }: { data?: UK_TYPES.TSubChapter }) => {
    const appCommand = DWeb.useAppCommand()
    return <DWeb.ResponsiveWindow>
        <DWeb.DelayRenderer
            url={`${UK_TYPES.QUIZ_ENDPOINT}?categories=${data?.data}&_limit=100`}
            renderOnData={(res: any) => {
                let qn = DWebTS.decode(res.out) as UK_TYPES.TQuestion[];
                let c = qn.filter(x => (x.answer || "").trim().length > 4).length

                return (<DWeb.DCol>
                    <DWeb.DBox style={{ margin: 10 }}>
                        <DWeb.DashboardFig label={"your setup status"} value={c / qn.length * 100} subtext={`your have ${c} setup out of ${qn.length}`} ></DWeb.DashboardFig>
                    </DWeb.DBox>

                    {qn.map((x, idx) => <DWeb.DBox style={{ margin: 20 }}>
                        <DWeb.DText>Question {idx + 1}</DWeb.DText>
                        <OneQuestion data={x} onUserChangeResult={() => { }} config={{
                            isAdmin: true,
                            editAnswer: appCommand.isGkEnabled('gk_edit_enabled'),
                            onSaveResult: async (id, answer) => {
                                await appCommand.fireAndForget(async () => {
                                    dassert.verifyOrCrash(answer.trim().length > 0, "You must have a valid answer");
                                    await dnetwork.postSimpleStore(UK_TYPES.QUIZ_ENDPOINT + "/update", { _id: id, answer: answer });
                                });
                            },

                        }} />
                    </DWeb.DBox>)}
                </DWeb.DCol>)
            }} />
    </DWeb.ResponsiveWindow>
}