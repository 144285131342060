/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-undef */
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Alert, AlertTitle, Button, ButtonGroup, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material";
import { TObject, TVoidCalBack, dcolors, dlog } from "corexxx";
import React, { useState } from "react";
import { DHook } from "../../libs/DHook";
import { DWeb } from "../../libs/DWeb";
import { UK_TYPES } from "../data/model";

export type TQuestionResult = { id: string; answer?: string | undefined; is_correct?: boolean | undefined };

export type TQuestionConfig = {
  title?: string;
  immediate_response?: boolean;
  showNavigation?: boolean;
  navigationClickDisabled?: boolean;
  showActionButton?: boolean;
  actionButtons?: {
    next?: TVoidCalBack;
    previous?: TVoidCalBack;
    reset?: TVoidCalBack;
    finish?: (result: { [key: string]: TQuestionResult }) => void;
    flag?: TVoidCalBack;
  };
  show_action_bar?: boolean;
  isAdmin?: boolean;
  editAnswer?: boolean;
  onConfirmPressed?: TVoidCalBack;
  onSQNext?: TVoidCalBack;
  onCompleteSet?: (score: UK_TYPES.TScore) => void;
  onSaveResult?: (id: string, answer: string) => void;
  show_skip_btn?: boolean;
  onSkipPressed?: TVoidCalBack;
};

// This willrender question set
export type TAllResult = { [key: string]: TOneQuestionResult }
export const QuestionSetUI = ({ questions, config }: { questions: UK_TYPES.TQuestion[]; config?: TQuestionConfig }) => {
  dlog.d("renderng QuestionSetUI ");
  let [idx, setIdx] = useState(0);
  let allResult = React.useRef<TAllResult>({});
  const [states, statesFn] = DHook.useObject<any>({ 1: 'done' })
  const [ansSet, ansSetFn] = DHook.useObject<any>({})

  return (
    <DWeb.DCol style={{ flex: 0 }}>
      {idx < questions.length ? (
        <>
          <DWeb.DText style={{ fontSize: 12, marginBottom: 10 }}>{`QUESTION ${idx + 1} OF ${questions.length}`}</DWeb.DText>
          <OneQuestion
            config={{
              ...config,
              onSQNext: () => setIdx(idx + 1),
              onSkipPressed: () => {
                if (states[idx] != 'flag') {
                  statesFn.set(idx, 'flag')
                  setIdx(idx + 1)
                } else {
                  statesFn.set(idx, undefined)
                }
              }
            }}
            data={questions[idx]}
            initialValue={ansSet[idx]?.answer}
            onUserChangeResult={(res: TOneQuestionResult) => {
              if (res.is_correct) {
                statesFn.set(idx, 'right')
              } else {
                statesFn.set(idx, 'wrong')
              }
              ansSetFn.set(idx, res)
              allResult.current[res.id] = res;
            }}
          />
          {config?.actionButtons && config?.showActionButton ? (
            <DWeb.DRow style={{ marginTop: 140, marginBottom: 30 }}>
              <Button variant="contained" style={{ width: 100, marginRight: 10 }} disabled={idx == 0} onClick={() => setIdx(idx - 1)}>
                Previous
              </Button>
              <Button
                variant="contained"
                style={{ width: 100, marginRight: 10 }}
                disabled={idx >= questions.length - 1}
                onClick={() => {

                  setIdx(idx + 1)
                }}
              >
                Next
              </Button>
              <DWeb.DSpace />
              <ButtonGroup style={{ marginRight: 10 }}>
                <Button onClick={config?.actionButtons?.reset}>Reset</Button>
              </ButtonGroup>

              <Button
                variant="contained"
                style={{ width: 100 }}
                onClick={() => {
                  config?.actionButtons?.finish?.(allResult.current);
                }}
              >
                Finish
              </Button>
            </DWeb.DRow>
          ) : null}
          {config?.showNavigation ? (
            <DWeb.DRow style={{ marginTop: 20, display: "block", paddingTop: 30, borderTop: "1px solid #f1f1f1" }}>
              {questions.map((x, idx1) => {
                let backgroundColor = undefined;
                if (states[idx1] == 'right') {
                  backgroundColor = dcolors.pallets.green100
                }
                else if (states[idx1] == 'wrong') {
                  backgroundColor = dcolors.pallets.red50
                }
                else if (states[idx1] == 'flag') {
                  backgroundColor = dcolors.pallets.yellow100
                }
                // this should override
                if (idx1 == idx) {
                  backgroundColor = dcolors.pallets.blue300
                }
                return (
                  <Chip
                    key={idx1}
                    label={idx1 + 1}
                    onClick={!config.navigationClickDisabled ? () => setIdx(idx1) : undefined}
                    style={{
                      margin: 5,
                      backgroundColor: backgroundColor,
                    }}
                  ></Chip>
                );
              })}
            </DWeb.DRow>
          ) : null}
        </>
      ) : (
        <>
          <ResultUI_
            questions={questions}
            result={allResult.current}
            onComplete={(obj) => config?.onCompleteSet?.(obj)}
            onAction={(x) => {
              if (x === "continue") {
                config?.actionButtons?.finish?.(allResult.current);
              }
              if (x === "retake") {
                allResult.current = {};
                setIdx(0);
              }
            }}
          />
        </>
      )}
    </DWeb.DCol>
  );
};

const ResultUI_ = ({
  questions,
  result,
  onAction,
  onComplete,
}: {
  questions: UK_TYPES.TQuestion[];
  result: TAllResult;
  onComplete: (score: UK_TYPES.TScore) => void;
  onAction: (action: "continue" | "retake") => void;
}) => {
  let count = Object.values(result).filter((x) => x?.is_correct === true).length;
  let passrate = (count / questions.length) * 100;
  React.useEffect(() => {
    if (result) {
      let score = { total_count: Object.values(questions).length, correct_count: Object.values(result).filter((x) => x.is_correct).length };
      onComplete(score);
    }
  }, [result]);
  return (
    <DWeb.DCol style={{ flex: 0 }}>
      <DWeb.DashboardFig value={passrate || 0} label="Your Score" subtext={`You answered ${count} out of ${questions.length} questions correctly.`} />
      <DWeb.DRow style={{ justifyContent: "center", marginBottom: 30, marginTop: 30 }}>
        <Button style={{ marginRight: 20 }} variant="contained" onClick={() => onAction("continue")}>
          Continue
        </Button>
        <Button variant="outlined" onClick={() => onAction("retake")}>
          RETAKE QUIZ
        </Button>
      </DWeb.DRow>
      {questions.map((x, idx) => {
        return (
          <DWeb.DCol style={{ marginTop: 10, padding: 10, background: "#f1f1f1", position: "relative" }} key={idx}>
            <DWeb.Html html={`${x.prompt}`} style={{ color: dcolors.pallets.grey800, fontWeight: "bold", marginLeft: 0 }}></DWeb.Html>
            <DWeb.Html style={{ color: dcolors.pallets.grey600, fontSize: 14 }} html={x.explanation} />
            <span style={{ position: "absolute", top: -10, right: -10 }}>
              {result[x._id]?.is_correct ? (
                <CheckCircleIcon style={{ color: dcolors.pallets.green500, fontSize: 18 }} />
              ) : (
                <CancelIcon style={{ color: dcolors.pallets.red500, fontSize: 18 }} />
              )}
            </span>
          </DWeb.DCol>
        );
      })}
    </DWeb.DCol>
  );
};

export type TOneQuestionResult = { id: string; answer?: string; is_correct?: boolean }

export const OneQuestion = ({
  data,
  initialValue,
  onUserChangeResult,
  config,
}: {
  data: UK_TYPES.TQuestion;
  initialValue?: string
  onUserChangeResult: (result: TOneQuestionResult) => void;
  config?: TQuestionConfig;
}) => {
  if (!data) {
    return null;
  }
  const [userResult, setUserResult] = useState<TOneQuestionResult>({ id: data._id });
  let [error, setError] = useState<boolean | undefined>(undefined);
  let [result, setResult] = useState<string>("");
  let [is_correct, set_is_correct] = useState<boolean | undefined>(undefined);
  let [user_result, user_result_fn] = DHook.useObject<TObject>({})

  let [disable, set_disabled] = useState(false);
  React.useEffect(() => {
    setError(undefined);
    setResult("");
    set_is_correct(undefined);
    set_disabled(false);
    let mp: TObject = {};
    (initialValue || '').split(',').forEach((x: any) => mp[x] = true)
    user_result_fn.reset(mp)
  }, [data]);

  React.useEffect(() => {
    let ans: string[] = [];

    Object.keys(user_result).forEach((x) => {
      if (user_result[x] === true &&
        x.length > 5) {
        ans.push(x);
      }
    });
    setResult(ans.sort().join(","));
  }, [user_result]);

  React.useEffect(() => {
    setUserResult({ id: data._id, answer: result, is_correct: result == data.answer });
    if (!result || result.trim() != "") {
      onUserChangeResult({ id: data._id, answer: result, is_correct: result == data.answer });
    }
  }, [result]);

  return (
    <DWeb.DCol style={{ minHeight: 300 }}>
      <DWeb.Html html={data.prompt} style={{ margin: 0, padding: 0, fontSize: 18, color: dcolors.pallets.black }} />
      <div>
        {data.display_type === "checkbox" ? (
          <FormControl required error={error} component="fieldset" variant="standard">
            <FormGroup>
              {data.options.map((x) => {
                let text = x.text
                if (config?.isAdmin) {
                  text = `[${x.id}] ${x.text}`
                }
                return <FormControlLabel
                  key={x.id}
                  control={
                    <Checkbox
                      disabled={disable}
                      checked={user_result[x.id] == true}
                      onChange={(e) => {
                        user_result_fn.set(e.target.name, e.target.checked)
                      }}
                      name={x.id}
                    />
                  }
                  label={<DWeb.Html html={text} />}
                />
              })}
            </FormGroup>
          </FormControl>
        ) : (
          <FormControl required error={error} component="fieldset" variant="standard">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(e) => {
                user_result_fn.reset({ [e.target.value]: true })
              }}
            >
              {data.options?.map((x) => {
                let text = x.text
                if (config?.isAdmin) {
                  text = `[${x.id}] ${x.text}`
                }
                return <FormControlLabel key={x.id} value={x.id} control={<Radio disabled={disable} checked={user_result[x.id] == true} />} label={<DWeb.Html html={text} />} />
              })}
            </RadioGroup>
          </FormControl>
        )}
      </div>

      {is_correct !== undefined && (config?.immediate_response || config?.isAdmin) ? (
        <Alert severity={is_correct ? "success" : "error"} style={{ marginTop: 20 }}>
          <AlertTitle>{is_correct ? "This answer is correct." : "This answer is incorrect."}</AlertTitle>
          <DWeb.Html html={data.explanation} style={{ paddingBottom: 5 }} />
        </Alert>
      ) : null}

      {config?.show_action_bar ? (
        <DWeb.DRow style={{ flex: 0 }}>
          <DWeb.DSpace />
          {is_correct !== undefined ? (
            <Button
              variant="contained"
              style={{ alignSelf: "flex-end", marginTop: 20 }}
              onClick={() => {
                config?.onSQNext?.();
              }}
            >
              Next
            </Button>
          ) : (
            <DWeb.DRow style={{ justifyContent: "end", marginTop: 20 }}>
              <Button
                variant="contained"
                disabled={Object.keys(user_result).length == 0}
                onClick={() => {
                  set_disabled(true);
                  set_is_correct(result === data.answer);
                  config?.onConfirmPressed?.();
                }}
              >
                Confirm
              </Button>
              {config?.show_skip_btn ? (<Button
                variant="outlined"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  config?.onSkipPressed?.();
                }}
              >
                Skip
              </Button>) : null}
            </DWeb.DRow>
          )}
        </DWeb.DRow>
      ) : null}

      {config?.isAdmin ? (<DWeb.DCol style={{ marginTop: 10, backgroundColor: dcolors.pallets.yellow100, padding: 10 }}>
        <DWeb.Html html={data.explanation} />
        <DWeb.DText style={{ marginTop: 20 }}>Current Answer:{data.answer}</DWeb.DText>
      </DWeb.DCol>) : null}

      {config?.editAnswer ? (
        <DWeb.DRow style={{ padding: 20, marginTop: 10, backgroundColor: data.answer ? dcolors.pallets.green100 : dcolors.pallets.red100, flex: 0 }}>
          <DWeb.DText style={{ flex: 1 }}>
            Local Answer:{result || "-"}
            {"==="} Remote Answer: {data.answer}
          </DWeb.DText>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              config.onSaveResult?.(data._id, result);
            }}
          >
            Save Answer
          </Button>
        </DWeb.DRow>
      ) : null}

    </DWeb.DCol>
  );
};
