import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { DWeb } from "../libs/DWeb";
//import "../data/converter";
import { SimpleStoreClient } from "../libs/SimpleStoreClient";
import { DefaultAccountScreen } from "../libs/screens/DefaultAccountScreen";
import { UkData } from "./data/data";
import { Dashboard } from "./ui/Dashboard";
import { FAQ } from "./ui/FAQ";
import { Landing } from "./ui/Landing";
import { MockTest } from "./ui/MockTest";
// setting up config
SimpleStoreClient.Get().init(UkData.AppConfig);
DWeb.setAppConfig(UkData.AppConfig);

// Simple Component
const AccountScreen = () => {
  let appCommand = DWeb.useAppCommand()
  return <DefaultAccountScreen
    paymentConfig={{
      amount: 5,
      subscription_name: "crack_life_in_the_uk",
      direct_url: "https://buy.stripe.com/8wMcNr1fK2WbaTS7ss",
      public_key: appCommand.getAppConfig()?.stripe_public_key
    }} />
}

// define root App
function App() {
  return (
    <RecoilRoot>
      <DWeb.DHelmet config={{ ...UkData.AppConfig.seo, page_title: " Home Page" }} />
      <DWeb.DAppWrapper>
        <BrowserRouter>
          <DWeb.DAppInit appConfig={UkData.AppConfig} />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/test" element={<MockTest />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<AccountScreen />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="*" element={<DWeb.PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </DWeb.DAppWrapper>
    </RecoilRoot>
  );
}

export default App;
