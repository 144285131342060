import { Button, CircularProgress } from "@mui/material";
import { TObject, dcolors } from "corexxx";
import React from "react";
import { DWeb } from "../../libs/DWeb";
import { DWebTS } from "../../libs/DWebTS";
import { UkData } from "../data/data";
import { UK_TYPES } from "../data/model";
import { UkHelper } from "../data/network";
import { Certificate } from "./Certificate";
import { QuestionSetUI } from "./MCQ";
import { AppHolder } from "./Utils";

export const MockTest = () => {
  const [state, setState] = React.useState<"pre" | "in" | "end">("pre");
  const [endScreen, setEndScreen] = React.useState<any>();
  return (
    <AppHolder>
      <DWeb.DHelmet config={{ ...UkData.AppConfig.seo, page_title: "Mock Test  | Crack Life in the Uk Test " }} />

      <DWeb.ResponsiveWindow>
        <DWeb.DCol style={{ marginTop: 20 }}>
          {state == "in" ? (
            <DWeb.DBox style={{ flex: 0 }}>
              <DWeb.DRow style={{ paddingBottom: 20, borderBottom: "1px solid #f2f2f2", marginBottom: 30 }}>
                <DWeb.DText>Mock Life In The Uk Test</DWeb.DText>
                <DWeb.DSpace />
                <DWeb.DCountDownTimer
                  onTimeOut={() => setState("end")}
                  sec={2700}
                  style={{ color: dcolors.pallets.white, backgroundColor: dcolors.pallets.red300, padding: "6px 18px", borderRadius: 5 }}
                />
              </DWeb.DRow>
              <MockTestUI
                onFinish={(qn, result) => {
                  DWebTS.showConfirmationAndDo(() => {
                    setState("end");
                    setEndScreen(<MockResult qn={qn} result={result} />);
                  }, "Do you want to finish the test?");
                }}
              />
            </DWeb.DBox>
          ) : null}
          {state == "pre" ? (
            <DWeb.DBox style={{ flex: 0 }}>
              <DWeb.DText style={{ fontWeight: "bold" }}>Welcome to Free Life In the UK Test</DWeb.DText>
              <DWeb.Html html={UkHelper.copy_text.mock_test_desc} />
              <Button style={{ marginTop: 20, flex: 0, display: "block" }} variant="contained" onClick={() => setState("in")}>
                Start the Test
              </Button>
            </DWeb.DBox>
          ) : null}

          {state == "end" ? <>{endScreen}</> : null}
        </DWeb.DCol>
      </DWeb.ResponsiveWindow>
    </AppHolder>
  );
};

export const MockTestUI = React.memo(({ onFinish }: { onFinish?: (qn: UK_TYPES.TQuestion[], result: TObject) => void }) => {
  console.log("MockTestUI");
  const [questions, setQuestion] = React.useState<UK_TYPES.TQuestion[]>([]);
  const [loading, setLoading] = React.useState(false);
  DWebTS.useEffectAsync(async () => {
    setLoading(true);
    setQuestion((await UkHelper.getRandom(true, 24)) as UK_TYPES.TQuestion[]);
    setLoading(false);
  }, []);
  if (questions.length == 0) {
    return <DWeb.DText>Loading ....</DWeb.DText>;
  }
  return (
    <QuestionSetUI
      questions={questions}
      config={{
        show_skip_btn: true,
        showNavigation: true,
        showActionButton: true,
        actionButtons: {
          previous: () => { },
          next: () => { },
          reset: () => { },
          flag: () => { },
          finish: (obj) => {
            onFinish?.(questions, obj);
          },
        },
      }}
    />
  );
});

export const MockResult = React.memo((props: { qn: UK_TYPES.TQuestion[]; result: TObject }) => {
  let count = props.qn.length;
  let appCommand = DWeb.useAppCommand()
  let pass = 0;
  props.qn.forEach((e) => {
    if (props.result[e._id] == e.answer) pass++;
  });
  let per = (pass / count) * 100;
  return (
    <DWeb.DCol>
      <DWeb.DCol style={{ textAlign: "center", background: dcolors.pallets.yellow100, padding: 0, marginBottom: 50 }}>
        <DWeb.DCol style={{ position: "relative" }}>
          <CircularProgress variant="determinate" value={per} size={140} style={{ alignSelf: "center", marginTop: 30, marginBottom: 20 }} />
          <DWeb.DText style={{ fontSize: 40, position: "absolute", top: 70, left: "calc( 50% - 30px)", textAlign: 'center' }}>{per.toFixed(0)}%</DWeb.DText>
        </DWeb.DCol>
        <DWeb.DText mode="title">
          You {per < 75 ? "failed" : "passed"} in this test. You score is {per.toFixed(0)}%
        </DWeb.DText>
        <DWeb.DText style={{ textTransform: "uppercase", fontSize: 12, textAlign: "center", margin: 20 }}>
          You have provided {pass} right answer out of {count} question.
        </DWeb.DText>
      </DWeb.DCol>
      <DWeb.DBox>
        <Certificate date={new Date().toLocaleDateString()} studentName={appCommand.accountState?.name || "Guest"} score={per.toFixed(2)} />
      </DWeb.DBox>
    </DWeb.DCol>
  );
});
