import { dlog, TObject } from "corexxx";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import _ from "underscore";
import { DWeb } from "../../libs/DWeb";
import { UK_TYPES } from "../data/model";
import { UkHelper } from "../data/network";
const { persistAtom } = recoilPersist();
dlog.d("Atm inited");
let last_code = "";
let mInit = false
const courseStateAtom = atom<UK_TYPES.TCourseManifest | null>({
  key: "course_state_atom",
  default: null,
});

const currentSecCodeAtom = atom<string | undefined>({
  key: "current_sec_code_atom",
  default: undefined,
});
const quizDataAtom = atom<UK_TYPES.TQuestion[]>({
  key: "cquiz_data_code_atom",
  default: [],
});
const useStateAtom = atom<UK_TYPES.TUserState>({ key: "user_state_atom", default: { _id: "", user_id: "", state: {} } });



export const useCourse = () => {
  const appCommand = DWeb.useAppCommand(); // app command 
  let [courseViewModel, setCourseViewModel] = useRecoilState(courseStateAtom); // all course information + userdata
  let [courseManifest, setCourseManifest] = useRecoilState(courseStateAtom); // network course information

  let [current_sec_code, set_current_sec_code] = useRecoilState(currentSecCodeAtom); // cuurent section
  const [userState, setUserState] = useRecoilState(useStateAtom); // user-state

  const [quizData, setQuizData] = useRecoilState(quizDataAtom); // quiz data

  async function reloadAll() {
    if (mInit) { return }
    mInit = true

    dlog.d("Initializing course....ONLY ONCE");
    const userdata = await loadUserData();
    const manifest = await loadCourseManifest();
    // We need to do it inline to avoid major recursion issue
    if (userdata && manifest) {
      setCourseViewModel(UkHelper.buildCourseViewModel(manifest, userdata));
    }
    //await setQuizData(await UkHelper.getQuizData("ch2_value_and_principles"));
  }

  // load courseManifest
  async function loadCourseManifest(): Promise<UK_TYPES.TCourseManifest | undefined> {
    let data = await UkHelper.getCourseManifest();
    setCourseManifest(data);
    return data;
  }

  // load userData
  async function loadUserData(): Promise<UK_TYPES.TUserState | undefined> {
    if (appCommand.accountState === undefined) {
      dlog.d("updateState ignored");
      return;
    }
    appCommand.loading(true);
    try {
      let data = await UkHelper.getUserData(appCommand.accountState?._id);
      setUserState(data);
      appCommand.loading(false);
      return data;
    } catch (e: any) { }
    appCommand.loading(false);
  }

  async function updateDataAndViewModel(payload: TObject) {
    if (_.isNull(userState._id) || _.isEmpty(userState._id)) {
      dlog.d("updateState ignored");
    }
    try {
      appCommand.loading(true);
      await UkHelper.updateUserState(payload);
      await loadUserData();
      const userdata = await loadUserData();
      if (userdata && courseManifest) {
        setCourseViewModel(UkHelper.buildCourseViewModel(courseManifest, userdata));
      }
      appCommand.loading(false);
    } catch (e) {
      appCommand.loading(false)
    }
  }


  return {
    // Should be call once 
    init: reloadAll,
    current_sec_code: current_sec_code,
    userState: userState,
    quizData: quizData,
    setCurrentSecCode: (current_code: string) => {
      set_current_sec_code(UkHelper.getSectionIdFromSubSectionId(courseViewModel, current_code));
    },

    courseViewModel: courseViewModel,

    updateState: async (current_code: string) => {
      if (last_code === current_code) {
        return;
      }
      set_current_sec_code(UkHelper.getSectionIdFromSubSectionId(courseViewModel, current_code));
      last_code = current_code;
      await updateDataAndViewModel({ current_code: current_code })
    },
    updateCompletion: async (code: string, score: UK_TYPES.TScore) => {
      let payload: TObject = {};
      payload["state." + code] = score;
      await updateDataAndViewModel(payload)
    },
  };
};
