import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Typography } from "@mui/material";
import { dcolors, dnetwork, TObject } from "corexxx";
import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { DWeb } from "../../libs/DWeb";
import { DWebTS } from "../../libs/DWebTS";
import cover from "../assert/cover.png";
import { UkData } from "../data/data";
import { UK_TYPES } from "../data/model";
import { UkHelper } from "../data/network";
import { QuestionSetUI } from "./MCQ";
import { AppHolder } from "./Utils";

export const Landing = () => {
  const navigation = useNavigate() as any;
  const appCommand = DWeb.useAppCommand()
  const location = useLocation();
  if (appCommand.accountState && location.pathname === '/') {
    return <Navigate to="/dashboard" />;
  }
  return (
    <AppHolder>
      <DWeb.DCol
        style={{
          position: "relative",
          minHeight: 450,
          width: "100%",
          background: "#2196f3",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
          backgroundImage: `url(${cover})`,
          backgroundBlendMode: "multiply",
        }}
      >
        <DWeb.DHelmet
          config={{
            ...UkData.AppConfig.seo,
          }}
        />

        <Typography
          style={{ textAlign: "center", fontSize: 16, marginTop: 30, marginBottom: 10, color: dcolors.pallets.grey300, textTransform: "uppercase" }}
        >
          Introducing
        </Typography>
        <Typography style={{ textAlign: "center", fontSize: 45, color: dcolors.pallets.grey100, fontWeight: "bold" }}>
          Crack Life In the Uk Test
        </Typography>
        <Typography style={{ textAlign: "center", marginTop: 20, textTransform: "uppercase", letterSpacing: 1.5 }}>
          Premium course | 1000+ Question | Confidence score | Mock Test
        </Typography>
        <DWeb.DRow style={{ flex: 0, marginTop: 50 }}>
          <Chip
            style={{ background: dcolors.pallets.red400, color: "white" }}
            label="Try Free Test"
            onClick={() => {
              navigation("/test");
            }}
          ></Chip>
          <Chip
            style={{ background: dcolors.pallets.green500, color: "white", marginLeft: 30 }}
            label="Join Premium"
            onClick={() => {
              navigation("/account");
            }}
          ></Chip>
        </DWeb.DRow>
      </DWeb.DCol>
      <DWeb.ResponsiveWindow>
        <DWeb.DCol style={{ backgroundColor: "#f1f1f1" }}>
          <DWeb.ResponsiveWindow>
            {/*********-- Quick test -- ************/}
            <>
              <DWeb.DText style={{ textAlign: "center", fontSize: 24, marginTop: 65, marginBottom: 20 }}>Try quick test for free</DWeb.DText>
              <DWeb.DBox style={{ marginTop: 20 }}>
                <RandomFiveQuestion />
              </DWeb.DBox>
            </>

            {/*********-- Mission -- ************/}
            <>
              <DWeb.DText style={{ textAlign: "center", fontSize: 24, marginTop: 65, marginBottom: 20 }}>Mission</DWeb.DText>
              <DWeb.DBox style={{ width: "100%", flexDirection: "column", padding: 30 }}>
                <DWeb.DText style={{ textAlign: "center", fontWeight: "500", color: dcolors.pallets.grey900, fontSize: 20 }}>
                  {UkHelper.copy_text.mission_title}
                </DWeb.DText>
                <DWeb.DText>
                  <DWeb.Html style={{ marginTop: 30, color: dcolors.pallets.grey800 }} html={UkHelper.copy_text.mission_desc} />
                </DWeb.DText>
              </DWeb.DBox>
            </>
            {/*********-- Course Structure -- ************/}
            <CourseOutline />
          </DWeb.ResponsiveWindow>
        </DWeb.DCol>
      </DWeb.ResponsiveWindow>
    </AppHolder>
  );
};

export const RandomFiveQuestion = () => {
  const [questions, setQuestion] = React.useState<UK_TYPES.TQuestion[]>([]);
  const [loading, setLoading] = React.useState(false);
  DWebTS.useEffectAsync(async () => {
    setLoading(true);
    setQuestion((await UkHelper.getRandom()) as UK_TYPES.TQuestion[]);
    setLoading(false);
  }, []);
  if (questions.length == 0) {
    return <DWeb.DText>Loading ....</DWeb.DText>;
  }
  return <QuestionSetUI questions={questions} config={{ immediate_response: true, show_action_bar: true, title: "Quick test of your skill" }} />;
};

export const CourseOutline = React.memo(() => {
  const navigation = useNavigate() as any;
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [data, setData] = React.useState<TObject[] | null>(null);
  DWebTS.useEffectAsync(async () => {
    let data = await dnetwork.getSimpleStore("https://simplestore.dipankar.co.in/api/remote_nodel_course_manifest?_id=6368363188310b77b5de30f7");
    if (data.out[0].outline) {
      setData(data.out[0].outline);
    }
  }, []);
  return (
    <>
      <DWeb.DText style={{ textAlign: "center", fontSize: 24, marginTop: 65, marginBottom: 20 }}>Course Content</DWeb.DText>
      {data?.map((x: any, idx: any) => {
        return (
          <Accordion expanded={expanded == x.code} onChange={() => setExpanded(expanded == x.code ? false : x.code)} key={idx} disableGutters={true}>
            <AccordionSummary style={{ background: "#f9f9f9" }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <DWeb.DText style={{ color: dcolors.pallets.grey800 }}>
                {`[Ch ${idx + 1}] `}
                {x.title}
              </DWeb.DText>
            </AccordionSummary>
            <AccordionDetails>
              <DWeb.DCol>
                {x.subchapter
                  ? x.subchapter.map((y: any, idy: any) => {
                    return (
                      <DWeb.DRow key={idy} style={{ padding: 10 }}>
                        <DWeb.DText style={{ flex: 1, lineHeight: 2 }}>
                          {`${idx + 1}.${idy + 1} `}
                          {y.title}
                        </DWeb.DText>
                        <Chip
                          onClick={() => {
                            navigation("/dashboard");
                          }}
                          label="Try in DashBoard"
                          disabled={!y.free_preview}
                        />
                      </DWeb.DRow>
                    );
                  })
                  : null}
              </DWeb.DCol>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
});
