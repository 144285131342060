import { makeStyles } from "@material-ui/styles";
import { Button, Typography } from "@mui/material";
import { FacebookShareButton } from "react-share";
import { DWeb } from "../../libs/DWeb";
import { UkData } from "../data/data";

const useStyles = makeStyles((theme: any) => ({
  certificate: {
    fontFamily: "Arial, sans-serif",
    width: "600px",
    height: "500px",
    margin: "0 auto",
    textAlign: "center",
  },
  title: {
    fontSize: "36px",
    marginTop: "50px",
  },
  text: {
    fontSize: "18px",
    marginTop: "30px",
  },
  signature: {
    fontSize: "24px",
    marginTop: "50px",
  },
  printButton: {
    marginTop: "40px",
  },
}));

export const Certificate = ({ studentName, date, score }: { studentName: string; date: string; score: string }) => {
  const classes = useStyles();
  const handlePrint = () => {
    window.print();
  };
  const shareUrl = "https://cracklifeintheuk.grodok.com/certificate";
  return (
    <div className={classes.certificate}>
      <img src={UkData.AppConfig.logo} alt="Logo" width="50" height="50" style={{ marginBottom: "20px" }} />
      <Typography className={classes.title} variant="h1">
        Certificate of Completion
      </Typography>
      <Typography className={classes.text}>
        This certificate is awarded to:
        <br />
        {studentName}
        <br />
        for successfully completing the "Crack the Life in the UK" test on {date} with a score of {score}%.
      </Typography>
      <Typography className={classes.signature}>Crack Life In The Uk Team</Typography>
      <DWeb.DRow style={{ marginTop: 20, alignItems: "center", justifyContent: "center" }}>
        <Button className={classes.printButton} variant="contained" color="primary" onClick={handlePrint} style={{ marginRight: 20 }}>
          print certificate
        </Button>
        <FacebookShareButton
          url={shareUrl}
          quote={`I just passed the "Crack the Life in the UK" test on ${date} with a score of ${score}!`}
          className="Demo__some-network__share-button"
        >
          <Button className={classes.printButton} variant="contained" color="primary">
            Share on Facebook
          </Button>
        </FacebookShareButton>
      </DWeb.DRow>
    </div>
  );
};
