export namespace UK_TYPES {
  export const QUIZ_ENDPOINT = "https://simplestore.dipankar.co.in/api/remote_life_in_the_uk_quiz";
  export const MANIFEST_ENDPOINT = "https://simplestore.dipankar.co.in/api/remote_nodel_course_manifest?_id=6368363188310b77b5de30f7";
  export const USER_DB_ENDPOINT = "https://simplestore.dipankar.co.in/api/remote_user_data";
  export const GET_RANDOM_API = `${QUIZ_ENDPOINT}/get_random?display_type=in:radio,checkbox`;

  export type TState = "complete" | "in progress" | "not started";
  export type TScore = {
    total_count: number;
    correct_count: number;
  };
  // base
  type TCourseBase = {
    code: string;
    title: string;
    for_evaluation?: boolean; // not all section are for evalution
    scoreState?: TScore;
    completionState: TScore; // how many states are completed
  };
  // this is chapter
  export type TChapter = TCourseBase & {
    subtitle?: string;
    subchapter: Array<TSubChapter>;
  };

  // this is subchapter
  export type TSubChapter = TCourseBase & {
    type: "richtext" | "multiple_choice_test" | "code_compiler" | "rich_text_editor";
    data: any;
    free_preview?: boolean;
    show_skip_option?: boolean; // this will allow skipping
  };

  // manifest
  export type TCourseManifest = TCourseBase & {
    outline: Array<TChapter>;
    config?: {
      add_chapter_number?: boolean; // this will add chapter code
    };
    subtitle: string;
    mission?: { title: string; details: string };
    FAQ?: { question: string; answer: string }[];
    pricing?: { amount: number; features: string[] };
  };

  export type TQuestion = {
    _id: string;
    prompt: string;
    explanation: string;
    display_type: "radio" | "checkbox";
    answer: string;
    categories: string;
    options: {
      id: string;
      text: string;
    }[];
  };

  export type TContextTypeHtml = {
    _id?: string;
    display_type: "html";
    categories: "life_in_the_uk_text";
    prompt: string;
    explanation: string;
    code: string; //friendly id
  };

  // user states
  export type TUserState = {
    user_id: string;
    _id: string;
    current_code?: string;
    exam_timestamp?: string;
    state: { [key: string]: TScore };
  };
}
