import { Alert } from "@mui/material";
import { dtime } from "corexxx";
import { DWeb } from "../../libs/DWeb";

export const DefaultAccountScreen = ({ paymentConfig }: { paymentConfig?: DWeb.TPaymentConfig }) => {
    let appCommand = DWeb.useAppCommand();
    return (
        <DWeb.ResponsiveWindow>
            <DWeb.DBox style={{ margin: '80px auto', minWidth: 420, alignItems: 'center', padding: 0, justifyContent: 'center' }}>
                <DWeb.DCol style={{ backgroundColor: appCommand.getAppConfig()?.primary_color!!, width: '100%', padding: 10, alignItems: 'center', color: 'white' }}>
                    {appCommand.getAppConfig()?.app_name ? <DWeb.DText style={{ color: 'inherit', fontSize: 20 }}>{appCommand.getAppConfig()!!.app_name!!}</DWeb.DText> : null}
                    {appCommand.getAppConfig()?.app_slogan ? <DWeb.DText style={{ color: 'inherit', maxWidth: 300, fontSize: 14, textAlign: 'center', marginTop: 20 }}>{appCommand.getAppConfig()!!.app_slogan!!}</DWeb.DText> : null}
                </DWeb.DCol>

                <DWeb.AccountScreen
                    config={{
                        style: { boxShadow: 'none', borderRadius: 0, width: 420, padding: 30 },
                        extra_link: [{ text: "Go to dashboard", href: "/dashboard" }],
                        extra_ele: (<>
                            {appCommand.getSubscription() ? (
                                <Alert style={{ marginBottom: 20 }}>
                                    <span>
                                        Thanks for having subscription. It will expire on {dtime.getFormattedDate(appCommand.getSubscription()!!.expiry_date) || "None"}.
                                        You can download the recept from <a href={appCommand.getSubscription()?.recept_url}>here</a>
                                    </span>
                                </Alert>
                            ) : null}
                            {paymentConfig && !appCommand.getSubscription() ? <DWeb.StripePaymentDialog
                                style={{ marginBottom: 10 }}
                                config={paymentConfig}
                            /> : null}
                        </>)
                    }}
                />
            </DWeb.DBox>
        </DWeb.ResponsiveWindow>
    );
};
