import { Check, ShoppingCart } from "@mui/icons-material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MessageIcon from '@mui/icons-material/Message';
import SpeedIcon from '@mui/icons-material/Speed';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button, Chip } from "@mui/material";
import { dcolors } from "corexxx";
import { DWeb } from "../../libs/DWeb";
import { UkData } from "../data/data";
import { UK_TYPES } from "../data/model";
import { UkHelper } from "../data/network";
import { useCourse } from "./Hook";
import { TimelineNotification, YourPlans } from "./Utils";

export const ConfidenceScore = ({ improveScoreClicked, setPaperClicked }: { improveScoreClicked: (x: UK_TYPES.TSubChapter) => void, setPaperClicked: (x: UK_TYPES.TSubChapter) => void }) => {
  let appCommand = DWeb.useAppCommand();
  let { courseViewModel } = useCourse();
  let courseCommand = useCourse();
  return (
    <DWeb.DCol>
      <DWeb.DHelmet config={{ ...UkData.AppConfig.seo, page_title: "Confidence Score  | Crack Life in the Uk Test" }} />
      {appCommand.isFishFooding() ? <> <TimelineNotification />
        <YourPlans /></> : null}

      <DWeb.DText mode="page_subheader" style={{ marginTop: 20, marginBottom: 20 }}>
        Summary
      </DWeb.DText>
      <DWeb.DRow style={{ flex: 0 }}>
        <DWeb.DBox style={{ marginRight: 20, }}>
          <DWeb.DashboardFig
            value={UkHelper.getPercentageFromScore(courseCommand.courseViewModel?.scoreState)}
            label="Score"
            subtext={`${courseCommand.courseViewModel?.scoreState?.correct_count || 0}/${courseCommand.courseViewModel?.scoreState?.total_count || 0
              } question answered`}
          />
        </DWeb.DBox>

        <DWeb.DBox style={{}}>
          <DWeb.DashboardFig
            value={UkHelper.getPercentageFromScore(courseCommand.courseViewModel?.completionState)}
            label="Progress"
            subtext={`${courseCommand.courseViewModel?.completionState?.correct_count || 0}/${courseCommand.courseViewModel?.completionState?.total_count || 0
              } section completed`}
          />
        </DWeb.DBox>
      </DWeb.DRow>
      <DWeb.DRow style={{ marginTop: 20, flex: 0 }}>
        <Button style={{ ...styles.buttonStyle, backgroundColor: dcolors.pallets.yellow900, }} href="https://www.gov.uk/life-in-the-uk-test" target="_blank"> <ShoppingCart style={{ margin: 5 }} /> Book test</Button>
        <Button style={{ ...styles.buttonStyle, backgroundColor: dcolors.pallets.green600 }} href="https://lifeintheuktest.com/life-in-the-uk-test-book/" target="_blank" ><MenuBookIcon style={{ margin: 5 }} /> Read online</Button>
        <Button style={{ ...styles.buttonStyle, backgroundColor: dcolors.pallets.blue600 }} href="https://www.youtube.com/watch?v=5u9DaFeP8pg" target="_blank"><YouTubeIcon style={{ margin: 5 }} /> Watch online</Button>
        <Button style={{ ...styles.buttonStyle, backgroundColor: dcolors.pallets.red600, display: 'none' }} href="https://www.youtube.com/watch?v=5u9DaFeP8pg" target="_blank"><SpeedIcon style={{ margin: 5 }} /> Marathon Test</Button>
        <Button style={{ ...styles.buttonStyle, backgroundColor: dcolors.pallets.red600 }} href="https://discord.com/channels/1073015540215451798/1073015540215451800" target="_blank"><MessageIcon style={{ margin: 5 }} /> Join Discord</Button>
      </DWeb.DRow>
      <DWeb.DText mode="page_subheader" style={{ marginTop: 30, marginBottom: 30 }}>
        Details
      </DWeb.DText>

      {courseViewModel?.outline?.map((x: UK_TYPES.TChapter, idx: any) => (
        <DWeb.DBox style={{ marginBottom: 30 }} key={idx}>
          <DWeb.DCol>
            <DWeb.DRow style={{ marginBottom: 20, paddingBottom: 20, borderBottom: "1px solid #f1f1f1" }}>
              <DWeb.DText style={{ flex: 1 }}>{x.title}</DWeb.DText>
            </DWeb.DRow>
            {x.subchapter.map((y: UK_TYPES.TSubChapter, idy: any) => {
              let sp = UkHelper.getPercentageFromScore(y.scoreState);
              let color = getColor(sp);
              let extra = sp > 0 ? " (" + sp + "%)" : "";
              return (
                <DWeb.DCol style={{ marginLeft: 20 }} key={idy}>
                  <DWeb.DRow style={{ marginBottom: 10, justifyItems: "center", position: "relative" }}>
                    {UkHelper.getPercentageFromScore(y.completionState) === 100 ? (
                      <Check style={{ position: "absolute", left: -30, top: 4 }} color={"success"} />
                    ) : null}
                    <DWeb.DText style={{ flex: 1, color: dcolors.pallets.grey600 }}>
                      {idy + 1}
                      {". "}
                      {y.title}
                    </DWeb.DText>
                    {appCommand.isDebugMode() ? <Chip
                      disabled={!y.free_preview && appCommand.getSubscription() == null}
                      label={'setup'}
                      style={{ backgroundColor: 'yellow', marginRight: 20 }}
                      onClick={() => {
                        setPaperClicked(y);
                      }}
                    /> : null}

                    <Chip
                      disabled={!y.free_preview && appCommand.getSubscription() == null}
                      label={(y.type == "richtext" ? "Review Docs" : "Improve Score") + extra}
                      style={{ backgroundColor: color }}
                      onClick={() => {

                        improveScoreClicked(y);
                      }}
                    />
                  </DWeb.DRow>
                </DWeb.DCol>
              );
            })}
          </DWeb.DCol>
        </DWeb.DBox>
      ))}
    </DWeb.DCol>
  );
};

function getColor(x?: number): string {
  if (x === undefined) {
    return "#00000014";
  }
  if (x >= 75) {
    return dcolors.pallets.green400;
  } else if (x >= 40) {
    return dcolors.pallets.amber300;
  } else if (x > 0) {
    return dcolors.pallets.red300;
  } else {
    return "#00000014";
  }
}


const styles = DWeb.DStyleSheet.create({
  buttonStyle: {
    backgroundColor: "#81D4FA",
    color: "white",
    padding: '10px',
    margin: 10,
    flex: 1,
  },
});